import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useNavigate } from "react-router-dom";
export default observer(function ResponsiveFooterPage() {      
  const { commonStore,modalStore } = useStore();
  const currentyear = new Date().getFullYear();
  const navigate = useNavigate();
    return (
        <>
        <section data-bs-version="5.1" className="footer01 markm5 cid-ub15sfiGzU" id="footer01-f">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-11 card">
                <div className="mbr-section-btn list-wrapper">
                    <a className="btn btn-info-outline display-4" style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/");
                    }}>
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        Home
                    </a>
                    <a className="btn btn-info-outline display-4" style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/aboutus");
                    }}>
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        About Us
                    </a>                    
                    <a className="btn btn-info-outline display-4" style={{cursor:'pointer'}} 
                    onClick={()=>{
                        navigate("/contactus");
                    }}
                    >
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        Contact Us
                    </a>
                    <a className="btn btn-info-outline display-4" style={{cursor:'pointer'}} 
                    onClick={()=>{
                        navigate("/terms");
                    }}
                    >
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        Terms and Conditions
                    </a>
                    
                    <a className="btn btn-info-outline display-4" style={{cursor:'pointer'}} 
                    onClick={()=>{
                        navigate("/privacy");
                    }}>
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        Privacy Policy
                    </a>
                    <a className="btn btn-info-outline display-4" 
                    onClick={()=>{
                        navigate("/refund");
                    }}
                    style={{cursor:'pointer'}}>
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                        Refund Policy
                    </a>                    
                </div>                
            </div>            
        </div>
        <div className="row">
            <div className="col-12 col-lg-9 card">
                <p className="copyright mbr-fonts-style display-4">
                    © Copyright {currentyear} Ganpati Traders - All Rights Reserved&nbsp;</p>                
            </div>
            <div className="col-12 col-lg-3 card">
                <a href="https://trendydashboard.com" target="_blank">
                    <p>Developed by Trendy Dashboard</p>
                        </a>
            </div>
        </div>
    </div>
        </section>
        </>    
    )
})