import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyBlLKXR1I1BgoHN8uxVxvL6Tcq5uf6QvOo",
  authDomain: "ganpati-traders-e03e8.firebaseapp.com",
  projectId: "ganpati-traders-e03e8",
  storageBucket: "ganpati-traders-e03e8.appspot.com",
  messagingSenderId: "843445807295",
  appId: "1:843445807295:web:cdfc941fcdca0b7c20be0a"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);